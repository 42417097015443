import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = (props) => {
  const location = useLocation();

  const [ready, setReady] = useState(false)

  const scrollAndDisplay = React.useCallback(()=>{
    window.scrollTo(0, 0);
    setReady(true)
  }, [])

  useEffect(() => {
    scrollAndDisplay()
  }, [location]);

  return <>{ready && props.children}</>
};

export default ScrollToTop;