import React, { useState } from 'react'
import { Link } from "react-router-dom"


export default function PageNotFound() {

    function changeText(option){
        let el = document.getElementsByClassName('modifyText')[0]
        if (option === 'change'){
            el.innerHTML = 'Does Not Exist'
        }else{
            el.innerHTML = 'Not Found'
        }
    }

    return (
        <section style={{backgroundColor:"transparent", height:"100vh"}}>
            <p className="superBig">Sorry, Page&nbsp;<span className="orange modifyText" onMouseEnter={()=>changeText('change')} onMouseLeave={changeText}>Not Found</span></p>
            <p>Head back <Link to="/">home</Link></p>
        </section>
    )
}


