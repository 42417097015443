import React, { useState, useEffect, useRef, useCallback } from "react";
import { useHistory } from "react-router-dom"


import Carousel from "../components/Carousel"
import TextChanger from "../components/TextChanger"

import { Card, Row, Col } from "react-bootstrap";

import portfolio from "../images/portfolio.jpg"
import FoodEnieLogo from "../images/foodenie.png"
import phone from "../images/phone.png"
import demoGif from "../images/ai_detect.gif"

const skills = [
    "AWS", "STRIPE", "TERRAFORM", "DOCKER", "POSTGRES","MySQL",
    "JENKINS", "REACT", "REACT-NATIVE","GCP", "RUST", "GO", "OBJECTIVE-C"
]

const colors = [
    'pinkOnHover', 'greenOnHover', 'purpleOnHover',
    'blueOnHover','yellowOnHover','orangeOnHover','tealOnHover'
]

const greetings = ["WASSUP", "HOWDY", "ALOHA", "QUE ONDA", "SALUTATIONS", "..ERM..UH", "RIGHT..THIS IS AWKWARD"]
export default function HomeScreen(){
    
    const [greetingIdx, setGreetingIdx] = useState(0)
    const [selectedGreeting, setSelectedGreeting] = useState(greetings[0])
    const [scrollPosition, setScrolllPosition] = useState(window.scrollY)
    const [shiftBanner1, setShiftBanner1] = useState(0)
    const [shiftBanner2, setShiftBanner2] = useState(0)

    const windowWidth = window.innerWidth
    const largeScreen  = windowWidth >= 900

    const history = useHistory()

    const mainRef = useRef()
    const scrollRef = useRef(0)

    const handleScroll =()=>{
        if (scrollRef.current >=100){
            if (scrollRef.current > window.scrollY){
                setShiftBanner1(prev=>prev-5)
            }else{
                setShiftBanner1(prev=>prev+5)

            }
        }
        if (scrollRef.current >=100){
            if (scrollRef.current > window.scrollY){
                setShiftBanner2(prev=>prev+5)
            }else{
                setShiftBanner2(prev=>prev-5)

            }
        }


        scrollRef.current = window.scrollY
    }
 


    useEffect(()=>{
        let val = setTimeout(()=>{
            
            setSelectedGreeting(greetings[greetingIdx % greetings.length])
                

        },30)

        return ()=>clearTimeout(val)
    }, [greetingIdx])

 useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
}, []);

console.log("window ", window.innerWidth)

    return(
        <div ref={mainRef}>

           <Row style={{backgroundColor:"transparent"}}>
                <Col xs={4} md={{span:3, offset:1}}>
                    <div style={{borderRadius:150, width:largeScreen ? 275 : 150, height:largeScreen ? 275 : 150, overflow:"hidden", display:"flex",alignSelf:"center"}}>

                        <img  src={portfolio} width={largeScreen? "300":"170"} style={{alignSelf:"center"}}/>
                    </div>
                </Col>
                <Col xs={8}>
                    <Carousel dataSetChoice={0} />
                    <div className="carouselShadow" style={{ position: "relative", top:"95px", backgroundColor :"white" }}></div>
                </Col>
            </Row>
            <Row>
                <Col md={{offset:1}} style={{marginBottom:20}}>
                <p style={{textAlign:"center", display:"flex", alignSelf:"center",fontWeight:"bold", marginTop:10, color:"white", fontStyle:"italic", fontFamily:"sans-serif"}}>&#123; Fʊl·STæk·Dəvəl-uh-pEr &#125;</p>
                    <p style={{color:"white", fontStyle:"italic", marginBottom:0}}> Which translates to...</p><br/>
                    <span style={{textDecorationLine:"line-through", textDecorationColor:"red", textDecorationThickness:4,
                    color:"white", fontWeight:"bold", fontSize:largeScreen ? 26: 24}}>Google Search</span>
                    &nbsp;<span style={{ color:"rgb(50,230,180)",fontSize:largeScreen ? 24 : 20,  fontWeight:"bold", position:"relative", top:"-15px"}}>ChatGPT</span>
                    <span style={{ fontSize: largeScreen ? 26: 24,fontWeight:"bold", color:"grey"}}>writes my code</span>
                </Col>
            </Row>

            <Row>
                <Col md={{offset:4}}>
                
                    <div style={{backgroundColor:"rgba(60,60,60,0.6)", padding:20, borderRadius:15, borderColor:"rgba(255,255,255,0.2)", borderWidth:.5, borderStyle:"solid", boxShadow:"0px 0px 20px rgba(255,255,0,0.3)"}}>
                        <p style={{fontWeight:"600", color:"white", fontSize:20}}><span style={{fontWeight:"800", fontSize:28}} onMouseEnter={()=>setGreetingIdx(prev=>prev+1)}>{selectedGreeting}??!</span>&nbsp;I'm <TextChanger />
                        A passionate software engineer who loves a challenge. Aside from development, I love cars, skateboarding, food, and
                        meeting new poeple from all over the word. If you have any questions, or simply want to chat. Just reach out!</p>                    
                    </div>
                </Col>
            </Row>
            <div style={{display:"flex",flexDirection:"row", marginTop:20, padding:10, overflow:"hidden"}}>
                <div style={{transform:`translateX(${shiftBanner1}px)`, justifyContent:"space-between",transform:`translateX(${shiftBanner2}px)`, display:"flex", flex:1}}>

                    { skills.slice(0,7).map((skill, ix)=>
                    
 
                        <span key={ix} className={`colorHover ${colors[ix % colors.length]}`} style={{fontWeight:"700", fontSize:largeScreen ? 28:26}}>{skill}&nbsp;</span>

                )}
                </div>                           
              </div>

            <section  style={{backgroundColor:"transparent", width:"100%", alignContent:"center"}}>
                <Row style={{ flexDirection:"column", width:"100%", margin:0}}>
                    <Col md={{offset:5,span:1}} style={{marginBottom:20, textAlign:largeScreen? "left":"center"}}>
                        <h2 style={{fontWeight:"900", color:"white", textEmphasisStyle:"doublecirele",textEmphasisColor:"orange", fontSize:"4em"}}>Projects</h2>
                    </Col>
                    <Col xs={12} >
                        <Row style={{justifyContent:"space-around", textAlign:largeScreen? "left":"center"}}>
                            <Col xs={12} md={2} style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                                <div>
                                    <div style={{width:100}}>
                                        <img src={FoodEnieLogo} width={100} style={{borderRadius:15, boxShadow:"0px 0px 75px orange"}}/>
                                        <p style={{textAlign:"center", marginTop:5, fontWeight:"500"}}>FoodEnie</p>
                                    </div>
                                    <p>Only iOS (Beta)</p>
                                </div>
                            </Col>
                            <Col xs={12} md={3} style={{display:"flex", justifyContent:"center"}}>
                                <div style={{ position:"relative"}}>

                                    <img src={phone} width={200} style={{position:"absolute", left:-5, top:-4}}/>
                                    <img src={demoGif} width={189} height={400} style={{borderRadius:20, }}/>
                                </div>
                            </Col>
                            <Col xs={12} md={6}>
                            <div style={{justifyContent:"center"}}>
                                <p style={{fontWeight:"bold", marginTop:largeScreen ? "auto":15}}><span style={{color:"rgb(50,230,180)", fontSize:26, fontWeight:"800"}}>Image Recognition</span> through CNN's to detect various food items</p>
                                <p style={{fontWeight:"bold", marginTop:largeScreen ? "auto":15}}><span style={{color:"white", fontSize:26, fontWeight:"800"}}>Nutrition Calculator</span> to accurately monitor <span style={{color:"white", fontSize:26, fontWeight:"800"}}>YOUR</span> daily recommended nutritional intake</p>
                                <p style={{fontWeight:"bold", marginTop:largeScreen ? "auto":15}}>Easily add ingredients purchased from <span style={{color:"white", fontSize:26, fontWeight:"800"}}>MILLIONS</span>  of vendors through Foodenie's <span style={{color:"orange", fontSize:26, fontWeight:"800"}}>Barcode Scanner</span></p>
                            </div>
                            </Col>
                        </Row>
                    </Col>    
                </Row> 
            </section>


            <hr />

            <div style={{display:"flex",flex:1, flexDirection:"row",  marginTop:20, padding:10, overflow:"hidden"}}>
                <div style={{ justifyContent:"space-between",transform:`translateX(-${shiftBanner2}px)`, display:"flex", flex:1}}>
                { skills.slice(7).map((skill, ix)=>
                    
 
                    <span key={ix} className={`colorHover ${colors[ix % colors.length]}`} style={{fontWeight:"700", fontSize:largeScreen? 28:26, textAlign:"center", whiteSpace:"nowrap"}}>{skill}&nbsp;</span>

                )}   
                </div>
                                 
              </div> 

           

       

    </div>
    )
}