import React, { useEffect } from "react";

window.am4core.useTheme(window.am4themes_animated);


export default function MyChart(){
    const x = React.createRef()



    
    useEffect(()=>{
        if (!x.current){
            x.current = window.am4core.create("chartdiv", window.am4charts.XYChart)
        let data = [{year:"Year 1", value:100}, {year:"Year 2", value:150}, { year:"Year 3", value:250 },
                {year:"Year 4", value:500, disabled:false}]

        x.current.data = data
        let yearAxis = x.current.xAxes.push(new window.am4charts.CategoryAxis())
        yearAxis.dataFields.category = "year"
        // valueAxis.renderer.labels.template.fontSize = 20;
        let amountAxis = x.current.yAxes.push(new window.am4charts.ValueAxis())
        
        yearAxis.renderer.labels.template.fill = window.am4core.color("white");
        amountAxis.renderer.labels.template.fill = window.am4core.color("white");


        let lineSeries = x.current.series.push(new window.am4charts.LineSeries());
        lineSeries.dataFields.valueY = "value"
        lineSeries.dataFields.categoryX = "year"
        lineSeries.name = "Rate of increase of Skillz"
        lineSeries.strokeWidth = 3
        lineSeries.strokeDasharray = "5, 3";
        lineSeries.propertyFields.stroke = window.am4core.color("#75F267");

        let bullet = lineSeries.bullets.push(new window.am4charts.CircleBullet());
        bullet.disabled = true;
        bullet.propertyFields.disabled = "disabled"

        bullet.tooltipText = "Value: [bold]{value}[/]";
        
        var secondCircle = bullet.createChild(window.am4core.Circle);
        secondCircle.radius = 6;

        bullet.events.on("inited", function(event){
        animateBullet(event.target.circle);
        })

        let title = x.current.titles.create()

        title.text = "Number of Sales"
        title.fontSize = "2em"
        title.fill = "white"

        function animateBullet(bullet) {
            var animation = bullet.animate([{ property: "scale", from: 1, to: 5 }, { property: "opacity", from: 1, to: 0 }], 1000, window.am4core.ease.circleOut);
            animation.events.on("animationended", function(event){
            animateBullet(event.target.object);
            })
        }
        
    
    }return()=>{
            if (x.current){
            x.current.dispose()}
        }
    }, [])
    return(
        <div id="chartdiv" className="charts" style={{ width:"100%", height: "200px"}}></div>
    )
}