import React, { forwardRef, useContext, forwadRef } from "react"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { ThemeContext } from "../ThemeContext"

import {AiOutlineGithub as Github} from "react-icons/ai"
import { FaFacebook as FB } from "react-icons/fa";
 
const Footer = forwardRef(({props}, ref)=>{

    const { theme } = useContext(ThemeContext)

    return(
        <footer >
            <hr />
            <Row style={{width :"100%", textAlign:"center", height:"100px"}}>
                <Col style={{height:"100px"}}>
                    <h3 ref={ref} id="connect" className="darkText text-center">Stay connected</h3>
                    <Row>
                        <Col xs>
                            <a href="https://www.facebook.com/tony.allen.96" aria-label="Facebook" style={{color:"white"}}><FB size={42}/></a>
                        </Col>
                        <Col xs>
                            <a href="https://www.linkedin.com/in/dev-tony-allen/" aria-label="LinkedIn"><i className="fab fa-linkedin fa-2x" ></i></a>
                        </Col>
                        <Col xs>
                            <a href="https://twitter.com/antsthebul" aria-label="twitter"><i className="fab fa-twitter fa-2x"></i></a>                        
                        </Col>
                        <Col xs>
                            <a href="https://github.com/Antsthebul" aria-label="Github" style={{color:"white"}}><Github size={42}/></a>                       
                        </Col>
                    </Row>
                    <div id="copyRight" style={{marginTop:"50px"}}>
                        <p>&copy; {(new Date().getFullYear())} Antsthebul</p>

                    </div>
                </Col>
            </Row>
        </footer>

    )
})

export default Footer