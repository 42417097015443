import React, { useState, useEffect, useContext } from 'react'
import { ThemeContext } from "../ThemeContext"

import python_logo from "../images/python_scaled.png";
import javascript from "../images/javascript_scaled.png";
import css_logo from "../images/css_scaled.png";
import html_logo from "../images/html_scaled.png";
import tux from "../images/tux_scaled.png";
import nginx_logo from "../images/nginx_scaled.png";
import postgres_logo from "../images/postgres_scaled.png";
import docker_logo from "../images/docker_scaled.png";
import react_logo from "../images/react_scaled.png"
import jenkins_logo from "../images/jenkins_scaled.png"
import aws_logo from "../images/aws.png"
import arduino_logo from "../images/arduino_logo.png"
import celery_logo from "../images/celery_logo.png"
import redis_logo from "../images/redis_logo.png"



const FloatingDisplay =(props)=>{
    const {reverse } = props
  return(
        <div className={`carousel ${reverse && "carouselReverse"}`} style={{transform:`translateZ(${-props.radius}px) rotateY(${props.angle}deg)`}}>
            {props.children}
        </div>

  )  
}

function Face(props) {
    const ref = React.useRef()
    return(
        <div ref={ref} className="face">
            <img src={props.src} className='faceImg' alt={props.alt} width="100" height="100"/>
            {/* <p style={{ fontSize:props.fontSize}}>{props.label}</p> */}
            {props.children}</div>
    )
    
}

export default function Carousel(props) {

    const { dataSetChoice, reverse } = props
    
    const data =[
        [
            {label:"Python", lang:"python", image:{src:python_logo, alt:"Python Logo"}}, 
            {label:"JS", lang:"Javascript", image:{src:javascript, alt:"Javascript Logo"}}, 
            {label:"HTML", lang:"html", image:{src:html_logo, alt:"HTML Logo"}}, 
            {label:"CSS", lang:"css", image:{src:css_logo, alt:"CSS Logo"}}, 
            {label:"PostgreSql", lang:"postgres", image:{src:postgres_logo, alt:"Postgres Logo"}}, 
            {label:"Linux", lang:"linux", image:{src:tux, alt:"Tux the Linux Penguin"}}, 
            {label:"NGINX", lang:"nginx", image:{src:nginx_logo, alt:"NGINX Logo"}}],
        [ 
            {label:"Docker", lang:"docker", image:{src:docker_logo, alt:"Docker Logo"}},
            {label:"Jenkins", lang:"jenkins", image:{src:jenkins_logo, alt:"Jenkins Logo"}},            
            {label:"React", lang:"react", image:{src:react_logo, alt:"React Logo"}},
            {label:"AWS", lang:"aws", image:{src:aws_logo, alt:"AWS Logo"}},
            {label:"Arduino", lang:"ardiuno", image:{src:arduino_logo, alt:"Ardiuno Logo"}},
            {label:"Celery", lang:"celery", image:{src:celery_logo, alt:"Celery Logo"}},
            {label:"Redis", lang:"redis", image:{src:redis_logo, alt:"Redis Logo"}},

        ],


    ]   
    
    const [index, setIndex]= useState(0) 

    // setInterval(()=>{setIndex(index+1)}, 1000)

    const [carousel, setCarousel] = useState()
    const [radius, setRadius] = useState(0)
    const [angle, setAngle] = useState(0)
    const [cellSize, setCellSize] = useState(0)
    const [radiusWidth, setRadiusWidth] = useState()
    const [buttonSize, setButtonSize] = useState()
    const [buttonSpace, setButtonSpace] = useState()
    const [fontSize, setFontSize] = useState()
    const { windowSize} = useContext(ThemeContext)

    const setComponentWidth = React.useCallback(()=>{
        if (windowSize === "extra small"){
            setRadiusWidth(30)
            setButtonSize("fa-4x")
            setButtonSpace("150px")
            setFontSize("18px")
        }else{
            setRadiusWidth(7)
            setButtonSize("fa-6x")
            setButtonSpace("250px")
            setFontSize("16px")
        }

    }, [windowSize])

    
    const theta = 360/7 

    const createCarousel=()=>{
            setCarousel(document.getElementsByClassName('carousel')[0])
    }



    
//     function handlePrev(){
//         if(index === -6){
//             setIndex(0)
//         }else{
//             setIndex(prev=>prev-1);
    
//         }
        
// }

//     function handleNext(){
//         setIndex(prev=>(prev+1));
//     }
    
    function rotateCarousel(){
        if (index < 0){
            // setSelectedLang(data[(index + 7) % 7].lang)

        }else{
            // setSelectedLang(data[index % 7].lang)
   
        }
        setAngle(theta * index * -1)
    }
    

        useEffect(()=>{
            setComponentWidth()
            if(!carousel){
                createCarousel()
            }else{
                let cellWidth = carousel.offsetWidth
                setCellSize(cellWidth)
                  
                setRadius(Math.round((cellSize/2) /Math.tan(Math.PI / radiusWidth)))
                rotateCarousel()
            }
        }, [createCarousel, carousel, cellSize, setComponentWidth])


    return (
        <div className="scene">
            <FloatingDisplay radius={radius} angle={angle} reverse={reverse}>
             { data[dataSetChoice].map((language, ix)=>
                <Face key={ix} fontSize={fontSize} label={language.label} opacity={language.lang === props.selectedLang} src={language.image.src} alt={language.image.alt} />

             )}
            </FloatingDisplay>
            {/* <Row className="buttonsGroup">
                
                <Col xs={6}><button style={{right:buttonSpace}} onClick={handlePrev}><i className={`fas fa-chevron-left ${buttonSize}`}></i></button></Col>
                <Col xs={6}><button style={{left:buttonSpace }} onClick={handleNext}><i className={`fas fa-chevron-right ${buttonSize}`}></i></button></Col>
            </Row> */}
        </div>
    )
}

