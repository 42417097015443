import React, {  useEffect } from "react";


window.am4core.useTheme(window.am4themes_animated);


export default function MyPieChart(){
    const x = React.createRef()

    
    useEffect(()=>{
        if (!x.current){
            x.current = window.am4core.create("pieChart", window.am4charts.PieChart3D)
        let data = [{food:"Cereal", value:100}, {food:"Sushi", value:150}, { food:"Nothing", value:250 },
                {food:"Pizza", value:500}]

        x.current.data = data
        
 
        
        let pieSeries = x.current.series.push(new window.am4charts.PieSeries3D());
        pieSeries.dataFields.value = "value"
        pieSeries.dataFields.category = "food"
        pieSeries.name = "Important foods"

        pieSeries.labels.template.fill = window.am4core.color("white");
        
        let title = x.current.titles.create()

        title.text = "Daily Diet of a Dev"
        title.fontSize = "2em"
        title.fill = "white"
        
    }return()=>{
            if (x.current){
            x.current.dispose()}
        }
    }, [])
    return(
        <div id="pieChart" className="charts"></div>
    )
}