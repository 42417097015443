import React, { createContext, useEffect, useState } from "react";

export const ThemeContext = createContext()

export default function ThemeProvider(props){
    const [windowWidth, setWindowWidth] = useState()
    
    
    const themeObj = {
        theme : props.theme,
        windowSize: windowWidth}


    
    const radiusWidthCallback = React.useCallback(()=>{
        if(window.innerWidth < 700)
            setWindowWidth("extra small")
        else{
            setWindowWidth("normal")
        }
    }, [])
 
    
    window.addEventListener('resize', radiusWidthCallback)

    useEffect(()=>{
        radiusWidthCallback()
    }, [radiusWidthCallback])
    return(
        <ThemeContext.Provider value={themeObj}>
            <div class="homePage">
            <div style={{background:"rgba(20, 20, 20, 0.5)"}}>{props.children}</div></div></ThemeContext.Provider>
    )
}
