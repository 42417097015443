import React, { useState, Fragment } from "react"

export default function TextChanger(){


    
    const initialEl = <span><span className="yellow sectionMain">&nbsp;&nbsp;Tony</span><span className="teal sectionMain"> A.&nbsp;&nbsp;</span></span>
    
    const [text, setText] = useState(initialEl)


    function changeText(val){
        if (val==="on"){
            setText(<span className="newName sectionMain name">Antsthebul </span>)
        }else{
            setText(initialEl)
        }

    }    
    
    return(<span className="changeName" onMouseOver={()=>changeText('on')} onMouseOut={changeText}>{text}
        </span>)
}