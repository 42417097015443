import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import React, { useState, useRef } from "react"
import HomeScreen from './screens/HomeScreen';
import ThemeProvider from "./ThemeContext"
import Footer from "./components/Footer"
import { BrowserRouter as Router, Switch, Route, useLocation} from "react-router-dom"

import { Link } from "react-router-dom"
import { Nav, Navbar } from "react-bootstrap";

import PageNotFound from "./screens/PageNotFound"
import SkillScreen from './screens/SkillScreen';
import AboutScreen from './screens/AboutScreen';
import ScrollToTop from "./components/ScrollToTop"


function TopNav(props){

  const location = useLocation()
  
  const {pathname} = location

  const navRoutes = [
    { path :"/", text:"Home"},
    {path:"/skills", text:"Skills"},
    {path:"/about", text:"About"}
  ]

  function scrollDown(){
    window.scrollTo(0, window.innerHeight)
  }


  return(
      <Navbar id="mainNav"  className="justify-content-end">
        <Nav>
          {/* {navRoutes.map((route, ix)=>(
            <Nav.Item key={ix} className="navItem"><Link to={route.path} className={`hiddenLink ${route.path === pathname && "yellow" }`}>{route.text}</Link></Nav.Item>                

          ))} */}
            <Nav.Item  className="navItem hiddenLink" onClick={props.onClick}>Contact</Nav.Item>
        </Nav>  
    </Navbar>
  )
}
function App() {


  const [theme, setTheme] = useState('light')
  const footerRef = useRef()
  

  function clickToFooter(){
    footerRef.current.scrollIntoView()
  }
  function changeTheme(){
     if (theme === 'light'){
        setTheme('black')

     }else{
       setTheme('light')
     }
  }
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <ScrollToTop>
        <Switch>
          <TopNav onClick={clickToFooter}/>
        </Switch>
      <Switch>
        <Route exact path="/" component={HomeScreen} />
        <Route path="*" component={PageNotFound} />
      </Switch>
      <Footer ref={footerRef} />
      </ScrollToTop>
    </Router>
    </ThemeProvider>

  );
}

export default App;
